<template>
  <div class="m-order-pre">
    <div
      class="order-page"
      :style="
        '--aog-currency:\'' +
        SYSLANG.currency +
        '\'; --aog-totalprice:\'' +
        formatMoney(TotalMoney) +
        '\';'
      "
    >
      <router-link class="content address" to="/address?fromorder=1">
        <div v-if="addrinfo && addrinfo.ID > 0" class="info">
          <span class="area"
            >{{ addrinfo.Country }} {{ addrinfo.Province }} {{ addrinfo.City }}
            {{ addrinfo.County }}</span
          >
          <span class="detail">{{ addrinfo.AddressDetail }}</span>
          <span class="phone"
            >{{ addrinfo.ReceiverName }} {{ addrinfo.MobilePhone }}</span
          >
        </div>
        <div v-else class="info">{{ PAGELANG.notaddrss }}</div>
      </router-link>
      <div class="content shopping-list">
        <div class="big-title">{{ PAGELANG.gouwuqingdan }}</div>
        <div class="order-goods-list">
          <template v-for="(goods, index) in ShippingList" :key="index">
            <div class="goods">
              <van-image
                width="100"
                height="100"
                radius="8"
                :src="UPLOADBASE + goods.PdtThumb"
                fit="cover"
              />
              <div class="info">
                <div class="pdt-name">
                  <span class="name">{{ goods.PdtName }}</span>
                </div>
                <div class="pdt-props">
                  <span>{{ goods.SelPropsText }}</span>
                </div>
                <div class="pdt-props">
                  <span class="price">{{ goods.RPriceProp }}</span>
                  <span class="buy-count">×{{ goods.BuyCount }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="shopping-item">
          <span class="title">{{ PAGELANG.freight }}</span>
          <span v-if="ShippingFee > 0" class="value price">{{
            ShippingFee
          }}</span>
          <span v-else class="value">{{ PAGELANG.free }}</span>
        </div>
        <div class="shopping-item">
          <span class="title">{{ PAGELANG.remark }}</span>
          <van-field
            v-model="order.remark"
            right-icon="aog iconfont icon-right-02"
            :placeholder="PAGELANG.remarkdesc"
            input-align="right"
            readonly
            class="value"
            @click="showRemark = true"
          />
        </div>
      </div>
      <van-submit-bar
        v-if="!showDelete"
        :decimal-length="0"
        :placeholder="true"
        :price="TotalMoney * 100"
        :currency="SYSLANG.currency"
        :button-text="PAGELANG.submit"
        :label="SYSLANG.amountto + ': '"
        button-color="linear-gradient(to right, #e4c994, #cdaa66)"
        @submit="onSubmit"
      >
      </van-submit-bar>
    </div>

    <van-popup v-model:show="showRemark" position="bottom">
      <div class="remark-popup">
        <div class="header">
          <span class="cancel" @click="showRemark = false">{{
            SYSLANG.quxiao
          }}</span>
          <span class="title">{{ PAGELANG.remark }}</span>
          <span
            class="submit"
            @click="
              order.remark = orderRemark;
              showRemark = false;
            "
            >{{ SYSLANG.queding }}</span
          >
        </div>
        <van-field
          v-model="orderRemark"
          rows="6"
          type="textarea"
          maxlength="200"
          :placeholder="PAGELANG.remarkdesc"
          show-word-limit
          class="remark-input"
        />
      </div>
    </van-popup>
  </div>

  <div
    class="pc-order-pre"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="order-content">
      <div class="big-title">{{ PAGELANG.收货地址 }}</div>
      <van-radio-group
        v-model="addrid"
        shape="shape"
        checked-color="#000"
        class="address-block"
        @change="changeAddress"
      >
        <ul class="address">
          <li v-if="addressList.length <= 0 && addrinfo && addrinfo.ID > 0">
            <van-radio :name="addrinfo.ID">
              {{ addrinfo.Country }} {{ addrinfo.Province }}
              {{ addrinfo.City }} {{ addrinfo.County }}
              {{ addrinfo.AddressDetail }}
              <span class="phone">
                {{ addrinfo.ReceiverName }} {{ addrinfo.MobilePhone }}
              </span>
              <span v-if="addrinfo.IsDefault == 1" class="set-default"
                >({{ ADDRLANG.default }})</span
              >
              <a
                v-else
                class="set-default"
                @click.stop="setDefault(addrinfo.ID)"
                >{{ ADDRLANG.设置为默认地址 }}</a
              >
            </van-radio>
            <span class="edit" @click="editAddress(addrinfo.ID)">{{
              ADDRLANG.编辑地址
            }}</span>
          </li>
          <template v-else>
            <li v-for="(addr, index) in addressList" :key="index">
              <van-radio :name="addr.ID">
                {{ addr.Country }} {{ addr.Province }} {{ addr.City }}
                {{ addr.County }} {{ addr.AddressDetail }}
                <span class="phone">
                  {{ addr.ReceiverName }} {{ addr.MobilePhone }}
                </span>
                <span v-if="addr.IsDefault == 1" class="set-default"
                  >({{ ADDRLANG.default }})</span
                >
                <a
                  v-else
                  class="set-default"
                  @click.stop="setDefault(addr.ID)"
                  >{{ ADDRLANG.设置为默认地址 }}</a
                >
              </van-radio>
              <span class="edit" @click="editAddress(addr.ID)">{{
                ADDRLANG.编辑地址
              }}</span>
            </li>
          </template>
        </ul>
      </van-radio-group>
      <div class="addr-btns">
        <el-button
          v-if="addressList.length <= 0 && addrcount > 1"
          color="#000"
          @click="getAllAddress"
          >{{ PAGELANG.使用其他地址 }}</el-button
        >
        <el-button v-else color="#000" @click="editAddress()">{{
          ADDRLANG.addbtn
        }}</el-button>
      </div>
    </div>

    <div class="order-content">
      <div class="big-title">
        {{ PAGELANG.gouwuqingdan }}
      </div>
      <div class="pc-cart">
        <ul class="my-cart">
          <li class="cart-item header">
            <div class="pdt-name">{{ CARTLANG.商品信息 }}</div>
            <div class="price">{{ CARTLANG.价格 }}</div>
            <div class="count">{{ CARTLANG.buycount }}</div>
            <div class="control">{{ CARTLANG.小计 }}</div>
          </li>
          <li
            v-for="(item, index) in ShippingList"
            :key="index"
            class="cart-item"
          >
            <van-image
              width="100"
              height="100"
              radius="0"
              :src="UPLOADBASE + item.PdtThumb"
              fit="cover"
              class="thumb"
            />
            <div class="pdt-name">
              <div class="title" @click="viewGoods(item.GoodsID)">
                {{ item.PdtName }}
              </div>
              <div class="props">{{ item.SelPropsText }}</div>
            </div>
            <div class="price">
              {{ item.RPriceProp }}
            </div>
            <div class="count">{{ item.BuyCount }}</div>
            <div class="control price">
              {{ item.TotalRPrice }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="order-content">
      <div class="big-title">
        {{ PAGELANG.remark }}
      </div>
      <el-input
        v-model="order.remark"
        :rows="2"
        type="textarea"
        :placeholder="PAGELANG.remarkdesc"
        resize="none"
        class="remark"
      />
    </div>

    <div class="order-content">
      <div class="pc-cart">
        <div class="cart-menu">
          <div class="control"></div>
          <div class="submit">
            <span class="freight">
              {{ PAGELANG.freight + ": " }}
              <label v-if="ShippingFee > 0" class="price">{{
                formatMoney(ShippingFee)
              }}</label>
              <label v-else>{{ PAGELANG.free }}</label>
            </span>
            {{ SYSLANG.amountto + ": "
            }}<label class="price">{{ formatMoney(TotalMoney) }}</label>
            <el-button
              size="large"
              color="#cdaa66"
              style="margin-left: 20px"
              @click="onSubmit"
              >{{ PAGELANG.submit }}</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <AddressEdit
      :AddrID="addressid"
      @editAddress="editAddress"
      @onRefresh="getAllAddress"
    />
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";
import AddressEdit from "../components/AddressEdit.vue";

export default {
  components: {
    AddressEdit,
  },
  data() {
    return {
      addrinfo: {},
      CouponList: [],
      GoodsCount: 0,
      OrderCount: 0,
      OrderMoney: 0,
      ShippingList: [],
      TotalMoney: 0,
      gbuycounts: "",
      goodsids: "",
      ShippingFee: 0,
      order: {
        remark: "",
      },
      showRemark: false,
      addrcount: 100,
      addressList: [],
      addrid: 0,
      addressid: -1,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.order;
    this.ADDRLANG = this.LANG.pages.address;
    this.CARTLANG = this.LANG.pages.cart;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.orderdata = history.state.orderdata;
    if (!this.orderdata) {
      showToast({
        message: this.PAGELANG.notdata,
        forbidClick: true,
        onClose: () => {
          this.$router.back();
        },
      });
      return;
    }

    this.isfromcart = history.state.isfromcart;

    this.init();
  },
  methods: {
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    init() {
      let url = this.isfromcart
        ? this.actions.order_orderc
        : this.actions.order_order;
      this.axios
        .post(url, {
          orderdata: this.orderdata,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, order } = data;

          if (code == "SUCCESS") {
            this.CouponList = order.CouponList;
            this.GoodsCount = order.GoodsCount;
            this.OrderCount = order.OrderCount;
            this.OrderMoney = order.OrderMoney;
            this.ShippingList = order.ShippingList;
            this.TotalMoney = order.TotalMoney;
            this.gbuycounts = order.gbuycounts;
            this.goodsids = order.goodsids;
            this.addrinfo = order.addrinfo;
            this.addrcount = order.addrcount;
            this.isfromcart = order.isfromcart;
            this.addrid =
              order.addrinfo && order.addrinfo.ID ? order.addrinfo.ID : 0;

            this.getShippingFee();
          } else {
            showToast({
              message:
                code == "NOTLOGIN"
                  ? this.SYSLANG[msg]
                  : code == "ERROR" || code == "SUCCESS"
                  ? this.PAGELANG[msg]
                  : msg,
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                } else {
                  this.$router.back();
                }
              },
            });
          }
        });
    },
    // 计算运费
    getShippingFee() {
      if (this.addrinfo && this.addrinfo.ID) {
        this.axios
          .post(this.actions.order_shippingfee, {
            addrid: this.addrinfo.ID,
            gbuycounts: this.gbuycounts,
            goodsids: this.goodsids,
          })
          .then(({ data }) => {
            let { code, msg, shippingfee } = data;
            if (code == "SUCCESS") {
              this.ShippingFee = (shippingfee * 1).toFixed(2);
            } else {
              showToast(msg);
            }
          });
      }
    },
    // 提交订单
    onSubmit() {
      if (!this.addrinfo || !this.addrinfo.ID) {
        showToast(this.PAGELANG.notaddrss);
        return;
      }

      this.order.addrid = this.addrinfo.ID;
      this.order.shoppinglist = this.ShippingList;
      this.order.isfromcart = this.isfromcart;

      showLoadingToast({
        message: this.PAGELANG.正在提交订单,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.order_placeorder, {
          order: JSON.stringify(this.order),
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, orderid } = data;
          showToast({
            message:
              code == "NOTLOGIN"
                ? this.SYSLANG[msg]
                : code == "ERROR" || code == "SUCCESS"
                ? this.PAGELANG[msg]
                : msg,
            forbidClick: true,
            onClose: () => {
              if (code == "SUCCESS") {
                for (let key in history.state) {
                  delete history.state[key];
                }
                this.$router.replace({
                  path: "/order/detail",
                  query: {
                    orderid,
                    isfrom: "placeorder",
                  },
                });
              }
            },
          });
        });
    },
    // 读取所有地址
    getAllAddress() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios.get(this.actions.address).then(({ data }) => {
        closeToast();
        let { code, msg, list } = data;
        if (code == "SUCCESS") {
          this.addressList = list;
          console.log(this.addrinfo);
          if (list.length > 0 && (!this.addrinfo || !this.addrinfo.ID)) {
            this.addrinfo = list[0];
            this.addrid = list[0].ID;
          }
        } else {
          showToast({
            message: this.SYSLANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      });
    },
    setDefault(val) {
      console.log(val);
      // 设置默认地址
      this.axios.get(this.actions.address_setdefault + val).then(({ data }) => {
        let { code, msg } = data;
        console.log(code, msg);
        if (code == "SUCCESS") {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].ID == val) {
              this.addressList[i].IsDefault = 1;
            } else {
              this.addressList[i].IsDefault = 0;
            }
          }
        } else {
          showToast(
            code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg]
          );
        }
      });
    },
    editAddress(addrid = 0) {
      this.addressid = addrid;
    },
    changeAddress(val) {
      this.addrinfo.ID = val;
    },
  },
  updated() {
    if (this.pagedata.addrinfo) {
      this.addrinfo = this.pagedata.addrinfo;
      delete this.pagedata.addrinfo;
      this.getShippingFee();
    }
  },
};
</script>


<style src="../assets/css/order.css" scoped></style>
<style src="../assets/css/cartpc.css"></style>
<style scoped>
@media (max-width: 678px) {
  .pc-order-pre {
    display: none;
  }
  .order-page {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    min-height: 100vh;
    background-color: #f7f7f9;
  }

  .order-page >>> .van-submit-bar__text {
    text-align: left;
  }
  .order-page .content {
    padding: 3vw;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 2vw;
  }

  .order-page .content.address {
    display: flex;
    align-items: center;
    background: #fff url(../assets/images/address_bg.png) repeat-x 0 100%;
    background-size: auto 4px;
  }
  .order-page .content.address::after {
    content: "\e68b";
    font-family: "iconfont";
    font-size: 18px;
    margin-left: 10px;
    color: #000;
  }

  .order-page .content.address .info {
    display: flex;
    flex-direction: column;
    width: 0;
    flex-grow: 1;
    line-height: 24px;
    font-size: 14px;
    color: #000;
    padding: 4px 0;
  }
  .order-page .content.address .info .area {
    color: #7c7c7c;
  }

  .order-page .content.address .info .detail {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  .order-page .content .big-title {
    line-height: 36px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .order-page .content.shopping-list .shopping-item {
    display: flex;
    height: 12vw;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #000;
  }
  .order-page .content.shopping-list .shopping-item .title {
    width: 120px;
  }
  .order-page .content.shopping-list .shopping-item .value {
    width: 0;
    flex-grow: 1;
    text-align: right;
    --van-cell-vertical-padding: 0;
    --van-cell-horizontal-padding: 0;
    --van-field-icon-size: 14px;
  }

  .remark-popup {
    padding: 0 16px 16px 16px;
  }
  .remark-popup .header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666;
  }

  .remark-popup .header .submit {
    color: #fe295f;
  }
  .remark-popup .header .title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .remark-popup .remark-input {
    --van-cell-vertical-padding: 0;
    --van-cell-horizontal-padding: 0;
  }

  .remark-popup .remark-input >>> .van-field__control {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px 10px;
  }

  >>> .van-submit-bar__price-integer {
    font-size: 0;
  }
  >>> .van-submit-bar__price-integer::before {
    content: var(--aog-totalprice);
    font-size: var(--van-submit-bar-price-integer-font-size);
    font-family: var(--van-submit-bar-price-font);
  }
}
</style>